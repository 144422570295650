<template>
  <div class="copyAdsetBox">
    <div class="copyBox">
      <div
        class="copyTable"
        :class="{
          activeBox: copyCheckedList.length > 0,
          inActiveBox: copyCheckedList.length == 0,
        }"
      >
        <div
          class="copyTitle"
          :class="{
            activeChooseText: copyCheckedList.length > 0,
            inActiveChooseText: copyCheckedList.length == 0,
          }"
        >
          已选 {{ copyCheckedList.length }} 项
        </div>
        <el-checkbox-group
          v-model="copyCheckedList"
          class="copyCheckboxGroup"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="copyItem in copyOriginList"
            :label="copyItem.name"
            :key="copyItem.id"
            class="copyCheckboxContainer"
          >
            <div
              class="copyCheckbox"
              :title="copyItem.name"
            >
              {{ copyItem.name }}
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div>
        <span
          class="copyErrorText"
          v-if="copyCheckedList.length == 0"
          >请选择复制的广告系列</span
        >
      </div>
      <el-form
        :model="ruleForm"
        :rules="computedRules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item
          label="复制"
          prop="toAccountId"
        >
          <el-select
            v-model="ruleForm.toAccountId"
            placeholder="请选择目标账户"
            remote
            reserve-keyword
            filterable
            :remote-method="remoteMethod"
            clearable
            style="width: 272px"
            :loading="accountLoading"
          >
            <el-option
              v-for="item in accountList"
              :key="item.accountId"
              :label="item.accountName"
              :value="item.accountId"
            >
            </el-option>
          </el-select>
          <el-tooltip
            content="账户列表需要根据账户名称或者账户ID检索出来；如要跨账户复制，请先清空下拉框，根据要复制到的目标账户或者ID进行检索"
            placement="top"
          >
            <i
              class="el-icon-info"
              style="font-size: 16px; color: red"
            ></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          label="复制份数"
          prop="copyNum"
        >
          <el-input-number
            v-model="ruleForm.copyNum"
            class="copyNum"
            :step="1"
            :min="1"
            :max="10"
            style="width: 235px"
          ></el-input-number>
          <span class="textTen">最大十份</span>
        </el-form-item>
        <el-form-item
          label="关联商品"
          prop="copySaleType"
        >
          <el-radio-group v-model="ruleForm.copySaleType">
            <el-radio
              label="使用默认商品"
              class="copyRadio"
            ></el-radio>
            <el-radio
              label="使用指定商品"
              class="copyRadioSecond"
            >
            </el-radio>
            <el-radio
              v-if="originUtmSourceConfig !== 'SHOPLINE'"
              label="使用默认商品克隆"
            ></el-radio>
            <el-form-item v-if="ruleForm.copySaleType == '使用指定商品'" prop="promotionLink">
              <el-input
                v-model.trim="ruleForm.promotionLink"
                placeholder="请输入商品链接"
                size="small"
                clearable
                style="margin-top: 5px"
              />
            </el-form-item>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="复制后状态"
          prop="targetStatus"
        >
          <el-radio-group v-model="ruleForm.targetStatus">
            <el-radio
              label="开启"
              class="copyRadio"
            ></el-radio>
            <el-radio
              label="暂停"
              class="copyRadioSecond"
            ></el-radio>
            <el-radio label="系列开启（组、广告维持原状）"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="复制范围"
          prop="targetStatus"
        >
          <el-radio-group v-model="ruleForm.copyChildType">
            <el-radio
              label="全部广告组"
              class="copyRadio"
            ></el-radio>
            <el-radio label="投放中的广告组"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { searchAccountApi } from '@/api/google/account.js';
const STATUS_MAP = {
  开启: 'ENABLED',
  暂停: 'PAUSED',
  '系列开启（组、广告维持原状）': 'CAMPAIGN_ENABLED',
};
const COPY_SALE_TYPE_MAP = {
  使用默认商品: 'DEFAULT',
  使用指定商品: 'CUSTOM',
  使用默认商品克隆: 'CLONE',
};
export default {
  data() {
    return {
      ruleForm: {
        copyNum: 1,
        toAccountId: '',
        copySaleType: '使用默认商品',
        targetStatus: '开启',
        copyChildType: '全部广告组',
        promotionLink: '',
      },
      rules: {
        copyNum: [{ required: true, message: '请输入复制份数', trigger: 'blur' }],
        toAccountId: [{ required: true, message: '请选择目标账户', trigger: 'change' }],
        copySaleType: [{ required: true, message: '请选择关联商品类型', trigger: 'change' }],
        targetStatus: [{ required: true, message: '请选择目标状态', trigger: 'change' }],
        copyChildType: [{ required: true, message: '请选择复制的类型', trigger: 'change' }],
      },
      copyCheckedList: [],
      accountList: [],
      accountLoading: false,
      originUtmSourceConfig: '',
    };
  },
  computed: {
    computedRules() {
      const baseRules = {
        copyNum: [{ required: true, message: '请输入复制份数', trigger: 'blur' }],
        toAccountId: [{ required: true, message: '请选择目标账户', trigger: 'change' }],
        copySaleType: [{ required: true, message: '请选择关联商品类型', trigger: 'change' }],
        targetStatus: [{ required: true, message: '请选择目标状态', trigger: 'change' }],
        copyChildType: [{ required: true, message: '请选择复制的类型', trigger: 'change' }],
      };

      if (this.ruleForm.copySaleType === '使用指定商品') {
        baseRules.promotionLink = [{ required: true, message: '请输入商品链接', trigger: 'blur' }];
      }

      return baseRules;
    },
  },
  props: {
    copyOriginList: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: 'campaign',
    },
  },
  methods: {
    remoteMethod(query) {
      if (!query) {
        this.accountList = [];
        return;
      }
      this.getAccount(query);
    },
    async getAccount(keyword, getOriginConfig = false) {
      try {
        const params = {
          page: 1,
          pageSize: 1000,
          keyword: keyword,
        };
        this.accountLoading = true;
        const result = await searchAccountApi(params);
        this.accountLoading = false;
        this.accountList = result.data.list;
        if (getOriginConfig) {
          this.originUtmSourceConfig = this.accountList.find(
            (item) => item.accountId === this.copyOriginList[0].ad_accountId,
          )?.utmSourceConfig;
          this.updateDynamicValidator();
        }
      } catch {
        this.accountLoading = false;
      }
    },
    updateDynamicValidator() {
      // 如果originUtmSourceConfig已经设置，则添加自定义校验规则
      if (this.originUtmSourceConfig) {
        // 添加自定义校验规则到toAccountId
        this.rules.toAccountId.push({
          validator: this.customValidator,
          trigger: 'change',
        });
      }
    },
    customValidator(rule, value, callback) {
      if (value) {
        const currentConfig = this.getUtmSourceConfig();
        if (currentConfig !== this.originUtmSourceConfig) {
          callback(new Error('账户落地页类型不一致，不允许跨账户复制'));
        } else {
          callback();
        }
      } else {
        callback(); // 如果值为空，则不进行此检查，因为前面的必填项规则会处理这种情况
      }
    },
    getTargetStatus() {
      return STATUS_MAP[this.ruleForm.targetStatus] || null;
    },
    getCopySaleType() {
      return COPY_SALE_TYPE_MAP[this.ruleForm.copySaleType] || null;
    },
    getUtmSourceConfig() {
      return this.accountList.find((item) => item.accountId === this.ruleForm.toAccountId)?.utmSourceConfig || null;
    },
    getCopyParams() {
      if (this.copyCheckedList.length === 0) {
        return 'hasNoCampaiginListFlag';
      }

      const accountInfo = this.accountList.find((item) => item.accountId === this.ruleForm.toAccountId);
      const newAccountName = accountInfo ? accountInfo.accountName : null;
      const saleParam = {
        copySaleType: this.getCopySaleType(),
        ...(this.ruleForm.copySaleType === '使用指定商品' && { promotionLink: this.ruleForm.promotionLink }),
      };
      const adInfos = this.copyOriginList
        .filter((item) => this.copyCheckedList.includes(item.name))
        .map((ele) => {
          return {
            fromCampaignId: ele.campaign_id,
            // 为后续扩展做准备：如果修改名称，就传新的名称，如果不修改，就传null
            campaignName: null,
            toCampaignId: ele.campaign_id,
          };
        });
      const params = {
        level: this.level, // 广告层级
        adInfos: adInfos, // 复制广告列表
        fromAccountId: this.copyOriginList[0]?.ad_accountId, // 复制来源账户id
        fromAccountName: this.copyOriginList[0]?.ad_accountName, // 复制来源账户名称
        toAccountId: this.ruleForm.toAccountId, // 复制目标账户id
        toAccountName: newAccountName, // 复制目标账户名称
        copyNum: this.ruleForm.copyNum, // 复制份数
        targetStatus: this.getTargetStatus(), // 目标状态
        saleParam: saleParam, // 关联商品类型
        copyChildType: this.ruleForm.copyChildType === '全部广告组' ? 'total' : 'putIn', // 复制类型
      };
      return params;
    },
    handleCheckedCitiesChange() {
      console.log('this.copyCheckedList', this.copyCheckedList);
    },
  },
  mounted() {
    if (this.copyOriginList.length > 0) {
      this.ruleForm.toAccountId = this.copyOriginList[0].ad_accountId;
      this.getAccount(this.copyOriginList[0].ad_accountName, true);
    }
    this.copyCheckedList = this.copyOriginList.map((item) => item.name);
  },
};
</script>
<style lang="scss" scoped>
.copyAdsetBox {
  padding: 5px 40px;
}
.copyBox {
  padding-top: 20px;
  padding-right: 20px;
  .copyTable {
    padding-bottom: 30px;
    padding: 8px;
    border-radius: 8px;
    margin-left: 25px;
    margin-bottom: 10px;
    padding-left: 0;
    .copyTitle {
      font-size: 12px;
      border-bottom: 2px solid #f0f0f0;
      padding-bottom: 5px;
      padding-left: 15px;
    }
    .copyCheckbox {
      width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      top: 5px;
    }
  }
}
.copyErrorText {
  color: #f56c6c;
  font-size: 12px;
  position: relative;
  left: 28px;
  bottom: 10px;
}
.activeChooseText {
  color: #409eff;
}
.inActiveChooseText {
  color: #999999;
}
.activeBox {
  border: 1px solid #f0f0f0;
}
.inActiveBox {
  border: 1px solid #f56c6c;
}
.copyCheckboxGroup {
  padding-left: 15px;
}
.copyCheckboxContainer {
  margin-top: 4px;
}
.copyNum {
  width: 220px;
  margin-right: 15px;
}
.copyRadio {
  display: block;
  margin-bottom: 5px;
  margin-top: 12px;
}
.copyRadioSecond {
  display: block;
  margin-bottom: 5px;
}
.textTen {
  color: #999999;
  font-size: 12px;
}
</style>
