<template>
  <div>
    <components
      ref="copyForm"
      v-bind:is="copyType"
      :copyOriginList="copyOriginList"
      :level="level"
    ></components>
    <el-row class="sumit">
      <div style="display: flex; justify-content: flex-end; position: fixed; bottom: 30px; right: 30px">
        <el-button
          @click="copyCancel"
          style="margin-right: 10px"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="copySubmit"
          >直接复制</el-button
        >
        <el-button
          type="primary"
          @click="nextEdit"
          >下一步，编辑</el-button
        >
      </div>
    </el-row>
    <!-- 批量编辑草稿 -->
    <multiEdit
      :multiEditDialogVisible.sync="multiEditDialogVisible"
      :currentSelect="currentSelect"
      :accountId="accountId"
      @getList="getList"
    />
  </div>
</template>
<script>
import copyCampaign from './copyCampaign.vue';
import copyAdset from './copyAdset.vue';
import copyAd from './copyAd.vue';
import { quickCopyApi } from '@/api/google/account.js';
import { draftFromCopyParam } from '@/api/google/create';
import multiEdit from '@/views/google/adManagement/multiEdit/index.vue';
const giikinLodash = require('lodash');
export default {
  data() {
    return {
      quickCopyApi,
      multiEditDialogVisible: false,
      currentSelect: [],
      accountId: '',
    };
  },
  props: {
    level: {
      type: String,
      default: 'campaign',
    },
    copyOriginList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    copyType() {
      if (this.level === 'campaign') {
        return copyCampaign;
      } else if (this.level === 'adset') {
        return copyAdset;
      } else if (this.level === 'ad') {
        return copyAd;
      }
    },
  },
  components: {
    copyCampaign,
    copyAdset,
    copyAd,
    multiEdit,
  },
  methods: {
    copySubmit: giikinLodash.debounce(function () {
      this.$refs.copyForm.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const params = this.$refs.copyForm.getCopyParams();
          const warningMessages = {
            hasNoCampaiginListFlag: '请选择广告系列',
          };
          if (warningMessages[params]) {
            return;
          }
          console.log('params', params);
          try {
            this.$showLoading();
            const res = await quickCopyApi(params);
            console.log('res', res);
            this.$message.success('复制成功');
            this.$hideLoading();
            this.$emit('submitDrawerSuccess');
          } catch {
            this.$hideLoading();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
    copyCancel() {
      this.$emit('closeDrawer');
    },
    /**编辑成功回调 */
    getList() {
      this.$emit('submitDrawerSuccess');
    },
    /**下一步编辑 */
    nextEdit() {
      this.$refs.copyForm.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          const params = this.$refs.copyForm.getCopyParams();
          this.accountId = params.toAccountId || params.fromAccountId;
          const warningMessages = {
            hasNoCampaiginListFlag: '请选择广告系列',
          };
          if (warningMessages[params]) {
            return;
          }
          console.log('params', params);
          try {
            this.$showLoading();
            const res = await draftFromCopyParam(params);
            console.log('res', res);
            this.$hideLoading();
            if (res.code !== 0) {
              return;
            }
            this.currentSelect = res.data;
            this.multiEditDialogVisible = true;
          } catch {
            this.$hideLoading();
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
