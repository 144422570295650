<template>
  <div>
    <el-drawer
      v-if="drawerFlag"
      :title="drawerParams.title"
      :visible="drawerFlag"
      :size="drawerSize"
      @close="closeDrawer"
    >
     <slot></slot>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
        drawerSize: 500
    };
  },
  props: {
    drawerFlag: {
        type: Boolean,
        default: false
    },
    drawerParams: {
        type: Object,
        default: () => ({})
    }
  },
  methods: {
    closeDrawer() {
      this.$emit('closeDrawer')
    }
  },
}
</script>
