<template>
  <div class="copyAdsetBox">
    <div class="copyBox">
      <div
        class="copyTable"
        :class="{
          activeBox: copyCheckedList.length > 0,
          inActiveBox: copyCheckedList.length == 0,
        }"
      >
        <div
          class="copyTitle"
          :class="{
            activeChooseText: copyCheckedList.length > 0,
            inActiveChooseText: copyCheckedList.length == 0,
          }"
        >
          已选 {{ copyCheckedList.length }} 项
        </div>
        <el-checkbox-group
          v-model="copyCheckedList"
          class="copyCheckboxGroup"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="copyItem in copyOriginList"
            :label="copyItem.name"
            :key="copyItem.id"
            class="copyCheckboxContainer"
          >
            <div
              class="copyCheckbox"
              :title="copyItem.name"
            >
              {{ copyItem.name }}
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div>
        <span
          class="copyErrorText"
          v-if="copyCheckedList.length == 0"
          >请选择复制的广告</span
        >
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item
          label="复制至"
          prop="toAccountId"
        >
          <el-select
            v-model="ruleForm.toAccountId"
            placeholder="请选择目标账户"
            v-loading="accountLoading"
            clearable
            disabled
            style="width: 300px;"
          >
            <el-option
              v-for="item in accountList"
              :key="item.accountId"
              :label="item.accountName"
              :value="item.accountId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="复制份数"
          prop="copyNum"
        >
          <el-input-number
            v-model="ruleForm.copyNum"
            class="copyNum"
            :step="1"
            :min="1"
            :max="10"
            style="width: 235px"
          ></el-input-number>
          <span class="textTen">最大十份</span>
        </el-form-item>
        <el-form-item
          label="复制后状态"
          prop="targetStatus"
        >
          <el-radio-group v-model="ruleForm.targetStatus">
            <el-radio
              label="开启"
              class="copyOpen"
            ></el-radio>
            <el-radio
              label="暂停"
              class="copyPause"
            ></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { searchAccountApi } from '@/api/google/account.js';
const STATUS_MAP = {
  开启: 'ENABLED',
  暂停: 'PAUSED',
};
export default {
  data() {
    return {
      ruleForm: {
        copyNum: 1,
        toAccountId: '',
        targetStatus: '开启',
      },
      rules: {
        copyNum: [{ required: true, message: '请输入复制份数', trigger: 'blur' }],
        toAccountId: [{ required: true, message: '请选择目标账户', trigger: 'change' }],
        targetStatus: [{ required: true, message: '请选择目标状态', trigger: 'change' }],
      },
      copyCheckedList: [],
      accountList: [],
      accountLoading: false,
    };
  },
  props: {
    copyOriginList: {
      type: Array,
      default: () => [],
    },
    level: {
      type: String,
      default: 'adset',
    },
  },
  methods: {
    async getAccount(keyword) {
      try {
        const params = {
          page: 1,
          pageSize: 1000,
          keyword: keyword,
        };
        this.accountLoading = true;
        const result = await searchAccountApi(params);
        this.accountLoading = false;
        this.accountList = result.data.list;
      } catch {
        this.accountLoading = false;
      }
    },
    getTargetStatus() {
      return STATUS_MAP[this.ruleForm.targetStatus] || null;
    },
    getCopyParams() {
      if (this.copyCheckedList.length === 0) {
        return 'hasNoCampaiginListFlag';
      }
      const adInfos = this.copyOriginList
        .filter((item) => this.copyCheckedList.includes(item.name))
        .map((ele) => {
          return {
            fromCampaignId: ele.campaign_id,
            toCampaignId: ele.campaign_id,
            campaignName: null,
            fromAdGroupId: ele.adset_id,
            toAdGroupId: ele.adset_id,
            adGroupName: null,
            adId: ele.id,
            adName: null,
          };
        });
      const params = {
        level: this.level,
        adInfos: adInfos,
        fromAccountId: this.copyOriginList[0].ad_accountId,
        fromAccountName: this.copyOriginList[0].ad_accountName,
        toAccountId: this.copyOriginList[0].accountId,
        toAccountName: this.copyOriginList[0].accountName,
        copyNum: this.ruleForm.copyNum,
        targetStatus: this.getTargetStatus(),
      };
      return params;
    },
    handleCheckedCitiesChange() {
      console.log('this.copyCheckedList', this.copyCheckedList);
    },
  },
  mounted() {
    this.ruleForm.toAccountId = this.copyOriginList[0].ad_accountId;
    this.copyCheckedList = this.copyOriginList.map((item) => item.name);
    this.getAccount(this.copyOriginList[0].ad_accountName);
  },
};
</script>
<style lang="scss" scoped>
.copyAdsetBox {
  padding: 5px 40px;
}
.copyBox {
  padding-top: 20px;
  padding-right: 20px;
  .copyTable {
    padding-bottom: 30px;
    padding: 8px;
    border-radius: 8px;
    margin-left: 25px;
    margin-bottom: 10px;
    padding-left: 0;
    .copyTitle {
      font-size: 12px;
      border-bottom: 2px solid #f0f0f0;
      padding-bottom: 5px;
      padding-left: 15px;
    }
    .copyCheckbox {
      width: 280px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      top: 5px;
    }
  }
}
.copyErrorText {
  color: #f56c6c;
  font-size: 12px;
  position: relative;
  left: 28px;
  bottom: 10px;
}
.activeChooseText {
  color: #409eff;
}
.inActiveChooseText {
  color: #999999;
}
.activeBox {
  border: 1px solid #f0f0f0;
}
.inActiveBox {
  border: 1px solid #f56c6c;
}
.copyCheckboxGroup {
  padding-left: 15px;
}
.copyCheckboxContainer {
  margin-top: 4px;
}
.copyNum {
  width: 220px;
  margin-right: 15px;
}
.copyOpen {
  margin-bottom: 5px;
  margin-top: 5px;
}
.copyPause {
  margin-bottom: 5px;
}
.textTen {
  color: #999999;
  font-size: 12px;
}
</style>
